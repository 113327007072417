import React, {useEffect, useState} from "react";
import {Button, Divider, Grid, IconButton, Stack, TextField, Typography} from "@mui/material";
import {grey, red} from "@mui/material/colors";
import {useDispatch, useSelector} from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import SnackBar from "../snackBar";
import {orderDetailModalStyles} from "../../../../../assets/posStyles";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentService from "../../../../../services/PaymentService";
import {getPosOrderHistory, setSelectedOrder} from "../../../../../redux/actions/pos/order";
import {checkRegister} from "../../../../../services/RegisterService";
import {setRegisterData} from "../../../../../redux/actions/pos/register";
import CONSTANTS from "../../../../../constants";
import OrderTotalModal from "../orderTotalModal";

const buttonStyle = {
    backgroundColor: grey[200],
    textTransform: 'capitalize',
    borderRadius: '0.25rem',
    padding: '0.75rem 0.5rem',
    lineHeight: '1.5rem',
    fontSize: '1rem',
    color: 'black',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: grey[400]
    }
}

const cashFields = [
    {
        label: "Given Amount",
        name: "given_amount"
    },

    {
        label: "Change Amount",
        name: "change_amount"
    },

    {
        label: "Amount",
        name: "amount"
    }
]

const sideBarButtons = [
    {
        label: "Cash",
    },

    {
        label: "Credit Card"
    },

    {
        label: "Cheque"
    },

    {
        label: "Bank Transfer"
    }
]

const cashButtons = [
    {
        label: "Discount",
        name: "discount"
    },

    {
        label: "0.00",
        name: "total_amount"
    },

    {
        label: "10",
        name: "10"
    },

    {
        label: "20",
        name: "20"
    },

    {
        label: "30",
        name: "30"
    },

    {
        label: "40",
        name: "40"
    },

    {
        label: "50",
        name: "50"
    },

    {
        label: "100",
        name: "100"
    },

    {
        label: "Clear",
        name: "clear"
    }
]

const FinalPaymentModalLargeScreen = ({order, setOpenModal, setSnackbarParams}) => {
    const [selectedPaymentType, setSelectedPaymentType] = useState(0);
    const [savedPayments, setSavedPayments] = useState([]);
    const locationData = useSelector(state => state.pos.locationData);
    const [paymentAmounts, setPaymentAmounts] = useState({
        given_amount: 0,
        change_amount: 0,
        amount: 0,
        otherMethodsAmount: 0,
        due: 0,
        discount: 0,
        total_amount: 0,
    })
    const [values, setValues] = useState({
        discount: 0,
        tax: 0,
        subTotal: 0,
        total: 0
    })
    const [disablePaymentButtons, setDisablePaymentButtons] = useState(false);
    const [amountNumbers, setAmountNumbers] = useState([
        {
            label: "10",
            value: "0"
        },

        {
            label: "20",
            value: "0"
        },

        {
            label: "30",
            value: "0"
        },

        {
            label: "40",
            value: "0"
        },

        {
            label: "50",
            value: "0"
        },

        {
            label: "100",
            value: "0"
        }
    ]);
    const [openDiscountModal, setOpenDiscountModal] = useState(false);
    const [openOrderInfo, setOpenOrderInfo] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (order && order.OrderTotals) {
            const data = {
                discount: 0,
                tax: 0,
                subTotal: 0,
                total: 0
            }
            for (let i = 0; i < order.OrderTotals.length; i++) {
                if (order.OrderTotals[i].code === "discount") {
                    data.discount = order.OrderTotals[i].value;
                }

                if (order.OrderTotals[i].code === "tax") {
                    data.tax = order.OrderTotals[i].value;
                }

                if (order.OrderTotals[i].code === "cart_total") {
                    data.subTotal = order.OrderTotals[i].value;
                }

                if (order.OrderTotals[i].code === "order_total") {
                    data.total = order.OrderTotals[i].value;
                    setPaymentAmounts({...paymentAmounts, due: order.OrderTotals[i].value, total_amount: order.OrderTotals[i].value});
                }
            }
            setValues(data);
        }
    }, [order]);

    const handleOtherMethods = (value) => {
        setPaymentAmounts({...paymentAmounts, otherMethodsAmount: parseFloat(value)});
    }

    const addPaymentMethod = () => {
        const flag = checkPaymentMethodAlreadyExist();

        if (flag) {
            return
        } else {
            if (selectedPaymentType === 0) {
                setSavedPayments([...savedPayments, {
                    type: "Cash",
                    amount: parseFloat(paymentAmounts.given_amount)
                }])
            } else if (selectedPaymentType === 1) {
                setSavedPayments([...savedPayments, {
                    type: "Card",
                    amount: parseFloat(paymentAmounts.otherMethodsAmount)
                }])
            } else if (selectedPaymentType === 2) {
                setSavedPayments([...savedPayments, {
                    type: "Cheque",
                    amount: parseFloat(paymentAmounts.otherMethodsAmount)
                }])
            } else if (selectedPaymentType === 3) {
                setSavedPayments([...savedPayments, {
                    type: "Bank Transfer",
                    amount: parseFloat(paymentAmounts.otherMethodsAmount)
                }])
            }

            let payments = {}
            if (selectedPaymentType === 0) {
                console.log(paymentAmounts, "Here Payment Amounts");
                payments = {
                    ...paymentAmounts,
                    amount: (paymentAmounts.amount === "" ? 0 : (paymentAmounts.amount)) + (paymentAmounts.given_amount),
                };
                if (payments.amount >= paymentAmounts.total_amount) {
                    setDisablePaymentButtons(true);
                    payments = {
                        ...payments,
                        change_amount: ((payments.amount - paymentAmounts.total_amount).toFixed(2) * 1),
                        due: 0
                    };
                } else {
                    payments = {...payments, due: paymentAmounts.total_amount - payments.amount}
                }
            } else {
                payments = {
                    ...paymentAmounts,
                    amount: (paymentAmounts.amount === "" ? 0 : (paymentAmounts.amount)) + (paymentAmounts.otherMethodsAmount)
                };
                if (payments.amount >= paymentAmounts.total_amount) {
                    setDisablePaymentButtons(true);
                    payments = {
                        ...payments,
                        change_amount: ((payments.amount - paymentAmounts.total_amount).toFixed(2) * 1),
                        due: 0
                    };
                } else {
                    payments = {...payments, due: paymentAmounts.total_amount - payments.amount}
                }
            }

            setPaymentAmounts({...payments, given_amount: ""});
        }
    }

    const handleRemovePaymentMethod = (index) => {
        let payments = paymentAmounts;
        savedPayments.map((payment, payment_index) => {
            if (payment_index === index) {
                payments = {...payments, amount: payments.amount - payment.amount}
                if (payments.amount >= paymentAmounts.total_amount) {
                    payments = {...payments, change_amount: payments.change_amount - payment.amount, due: 0}
                } else {
                    payments = {...payments, change_amount: 0, due: paymentAmounts.total_amount - payments.amount}
                    setDisablePaymentButtons(false)
                }
            }
        })

        setPaymentAmounts(payments);
        setSavedPayments((prevPayments) =>
            prevPayments.filter((_, payment_index) => payment_index !== index)
        );
    }

    const checkPaymentMethodAlreadyExist = () => {
        for (let i = 0; i < savedPayments.length; i++) {
            if (selectedPaymentType === 0 && savedPayments[i].type === "Cash") {
                setSnackbarParams({
                    open: true,
                    message: "Cash Payment Method is Already Added",
                    severity: "error"
                })
                return true;
            } else if (selectedPaymentType === 1 && savedPayments[i].type === "Card") {
                setSnackbarParams({
                    open: true,
                    message: "Card Payment Method is Already Added",
                    severity: "error"
                })
                return true;
            } else if (selectedPaymentType === 2 && savedPayments[i].type === "Cheque") {
                setSnackbarParams({
                    open: true,
                    message: "Cheque Payment Method is Already Added",
                    severity: "error"
                })
                return true;
            } else if (selectedPaymentType === 3 && savedPayments[i].type === "Bank Transfer") {
                setSnackbarParams({
                    open: true,
                    message: "Bank Transfer Payment Method is Already Added",
                    severity: "error"
                })
                return true;
            }
        }

        return false;
    }

    const handleFullPayment = () => {
        let payments = {};
        payments = {...paymentAmounts, amount: paymentAmounts.due + paymentAmounts.amount};
        setDisablePaymentButtons(true);
        payments = {...payments, change_amount: 0, due: 0};

        if (selectedPaymentType === 0) {
            setSavedPayments([...savedPayments, {
                type: "Cash",
                amount: paymentAmounts.due
            }])
        } else if (selectedPaymentType === 1) {
            setSavedPayments([...savedPayments, {
                type: "Card",
                amount: paymentAmounts.due
            }])
        } else if (selectedPaymentType === 2) {
            setSavedPayments([...savedPayments, {
                type: "Cheque",
                amount: paymentAmounts.due
            }])
        } else if (selectedPaymentType === 3) {
            setSavedPayments([...savedPayments, {
                type: "Bank Transfer",
                amount: paymentAmounts.due
            }])
        }

        setPaymentAmounts(payments);
    }

    const handleNumberedPayment = (info) => {
        let payments = {};
        if (((paymentAmounts.given_amount) + parseFloat(info.name)) >= values.total) {
            payments = {...paymentAmounts, amount: values.total};
            setDisablePaymentButtons(true);
            payments = {...payments, change_amount: parseFloat(((paymentAmounts.given_amount === "" ? 0 : (paymentAmounts.given_amount)) + parseFloat(info.name) - (paymentAmounts.total_amount)).toFixed(2)), due: 0, given_amount: 0};

            if (selectedPaymentType === 0) {
                setSavedPayments([...savedPayments, {
                    type: "Cash",
                    amount: values.total
                }])
            } else if (selectedPaymentType === 1) {
                setSavedPayments([...savedPayments, {
                    type: "Card",
                    amount: values.total
                }])
            } else if (selectedPaymentType === 2) {
                setSavedPayments([...savedPayments, {
                    type: "Cheque",
                    amount: values.total
                }])
            } else if (selectedPaymentType === 3) {
                setSavedPayments([...savedPayments, {
                    type: "Bank Transfer",
                    amount: values.total
                }])
            }
        } else {
            payments = {...paymentAmounts, given_amount: (paymentAmounts.given_amount) + parseFloat(info.name), change_amount: 0}
        }
        setPaymentAmounts(payments);
    }

    const handleAmountButtonsClick = (info, index) => {
        if (info.name === "discount") {
            setOpenDiscountModal(true);
        } else if (info.name === "clear") {
            setPaymentAmounts({
                given_amount: 0,
                change_amount: 0,
                amount: 0,
                otherMethodsAmount: 0,
                due: values.total,
                discount: 0,
                total_amount: values.total,
            })
            setSavedPayments([])
        } else {
            const flag = checkPaymentMethodAlreadyExist();
            if (flag) {
                return;
            } else {
                if (info.name === "total_amount") {
                    handleFullPayment();
                } else {
                    handleNumberedPayment(info);
                }
            }
        }
    }

    // const handleAmountButtonsClick = (info, index) => {
    //     let payments = {};
    //
    //     if (info.name === "discount") {
    //         setOpenDiscountModal(true);
    //     } else if (info.name === "total_amount") {
    //         for (let i = 0; i < savedPayments.length; i++) {
    //             if (selectedPaymentType === 0 && savedPayments[i].type === "Cash") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Cash Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             } else if (selectedPaymentType === 1 && savedPayments[i].type === "Card") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Card Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             } else if (selectedPaymentType === 2 && savedPayments[i].type === "Cheque") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Cheque Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             } else if (selectedPaymentType === 3 && savedPayments[i].type === "Bank Transfer") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Bank Transfer Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             }
    //         }
    //
    //         payments = {...paymentAmounts, amount: paymentAmounts.due + paymentAmounts.amount};
    //         setDisablePaymentButtons(true);
    //         payments = {...payments, change_amount: 0, due: 0};
    //     } else if (info.name === "clear") {
    //         setPaymentAmounts({
    //             given_amount: "",
    //             change_amount: "",
    //             amount: "",
    //             otherMethodsAmount: "",
    //             due: values.total,
    //             discount: "",
    //             total_amount: values.total,
    //         })
    //         setSavedPayments([])
    //     } else {
    //         for (let i = 0; i < savedPayments.length; i++) {
    //             if (selectedPaymentType === 0 && savedPayments[i].type === "Cash") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Cash Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             } else if (selectedPaymentType === 1 && savedPayments[i].type === "Card") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Card Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             } else if (selectedPaymentType === 2 && savedPayments[i].type === "Cheque") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Cheque Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             } else if (selectedPaymentType === 3 && savedPayments[i].type === "Bank Transfer") {
    //                 setSnackbarParams({
    //                     open: true,
    //                     message: "Bank Transfer Payment Method is Already Added",
    //                     severity: "error"
    //                 })
    //                 return;
    //             }
    //         }
    //
    //         if (((paymentAmounts.given_amount * 1) + (info.name * 1)) >= values.total) {
    //             payments = {...paymentAmounts, amount: values.total};
    //             setDisablePaymentButtons(true);
    //             payments = {...payments, change_amount: (((paymentAmounts.given_amount === "" ? 0 : (paymentAmounts.given_amount * 1)) + (info.name * 1) - (paymentAmounts.total_amount)).toFixed(2) * 1), due: 0, given_amount: 0};
    //
    //             if (selectedPaymentType === 0) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Cash",
    //                     amount: values.total
    //                 }])
    //             } else if (selectedPaymentType === 1) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Card",
    //                     amount: values.total
    //                 }])
    //             } else if (selectedPaymentType === 2) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Cheque",
    //                     amount: values.total
    //                 }])
    //             } else if (selectedPaymentType === 3) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Bank Transfer",
    //                     amount: values.total
    //                 }])
    //             }
    //         } else {
    //             payments = {...paymentAmounts, given_amount: (paymentAmounts.given_amount * 1) + (info.name * 1), change_amount: 0}
    //         }
    //     }
    //
    //     if (info.name !== "clear" && info.name !== "discount") {
    //         if (info.name === "total_amount") {
    //             if (selectedPaymentType === 0) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Cash",
    //                     amount: paymentAmounts.due
    //                 }])
    //             } else if (selectedPaymentType === 1) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Card",
    //                     amount: paymentAmounts.due
    //                 }])
    //             } else if (selectedPaymentType === 2) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Cheque",
    //                     amount: paymentAmounts.due
    //                 }])
    //             } else if (selectedPaymentType === 3) {
    //                 setSavedPayments([...savedPayments, {
    //                     type: "Bank Transfer",
    //                     amount: paymentAmounts.due
    //                 }])
    //             }
    //         }
    //         console.log(payments, "Payments");
    //         setPaymentAmounts(payments);
    //     }
    // }

    const handleCashAmountChange = (value) => {
        setPaymentAmounts({...paymentAmounts, given_amount: parseFloat(value)});
    }

    const handleDiscountChange = (value) => {
        value = parseFloat(value);
        let payments = {}
        if (value > values.total) {
            payments = {...paymentAmounts, discount: values.total, total_amount: 0, due: 0, change_amount: 0};
            setPaymentAmounts(payments);
            return
        }

        if (value === "" || !value || value === 0) {
            payments ={...paymentAmounts, discount: 0, total_amount: values.total, due: values.total};
        } else {
            payments ={...paymentAmounts, discount: value, total_amount: values.total - value, due: values.total - value};
        }

        setPaymentAmounts(payments);
    }

    const handleOrderPayment = async () => {
        const data = {
            charge_amount: paymentAmounts.amount,
            discount_amount: paymentAmounts.discount === "" ? 0 : paymentAmounts.discount,
        }
        const response = await PaymentService.orderPayment(order.order_id, data);
        if (!response.error) {
            const response = await checkRegister();
            await dispatch(setRegisterData(response.data.data[0]));

            const orderData = [order, locationData, savedPayments];
            localStorage.setItem("orderData", JSON.stringify(orderData));
            const newTabUrl = `${CONSTANTS.BASE_URL}printBill/${order.order_id}`;
            const newTab = window.open(newTabUrl, '_blank');
            newTab.focus();

            dispatch(getPosOrderHistory());
            dispatch(setSelectedOrder(null));
            setSnackbarParams({
                open: true,
                severity: 'success',
                message: 'Payment Successful'
            })
            setOpenModal(false);
        } else {
            setSnackbarParams({
                open: true,
                severity: 'error',
                message: 'Payment Failed'
            })
        }
    }

    return(
        <>
            <Stack sx={{padding: '0.5rem 1rem', backgroundColor: grey[200]}}>

                <Stack direction='row' sx={{columnGap: '1rem'}}>
                    <Stack sx={{backgroundColor: 'white', borderRadius: '4px', width: '205px', minWidth: '205px', maxWidth: '205px', padding: '0.5rem', rowGap: '0.5rem'}}>
                        <Typography variant='h5' sx={{fontWeight: '600', textAlign: 'center'}}>Payment Method</Typography>
                        {
                            sideBarButtons.map((button, index)=> (
                                <Button sx={buttonStyle} key={index} variant='contained' onClick={()=> {
                                    setSelectedPaymentType(index);
                                    setPaymentAmounts({...paymentAmounts, otherMethodsAmount: 0})
                                }}>{button.label}</Button>
                            ))
                        }
                    </Stack>
                    <Stack sx={{backgroundColor: 'white', borderRadius: '4px', flexGrow: 1, padding: '0.5rem'}}>
                        <Stack direction='row' sx={{alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem'}}>
                            <Typography variant='h5' sx={{fontWeight: '600', textAlign: 'start'}}>
                                {
                                    selectedPaymentType === 0 ? 'Cash' :
                                        selectedPaymentType === 1 ? "Credit Cash" :
                                            selectedPaymentType === 2 ? "Cheque" :
                                                selectedPaymentType === 3 ? "Bank Transfer" :
                                                    "Unknown"
                                }
                            </Typography>
                            {(paymentAmounts.change_amount !== 0) && <Typography variant='h5' sx={{fontWeight: '600', textAlign: 'start', color: red[400]}}>
                                Change Amount : {locationData.Currency.currency_symbol}{(paymentAmounts.change_amount).toFixed(2)}
                            </Typography>}
                        </Stack>
                        <Stack direction='row' sx={{alignItems: 'end', columnGap: '1rem'}}>
                            {
                                <Grid container spacing={1}>
                                    {selectedPaymentType === 0 ? (
                                        cashFields.map((field, index) => (
                                            <Grid key={index} item xs={12} md={6} lg={4}>
                                                <TextField
                                                    value={paymentAmounts[field.name] === 0 ? "" : paymentAmounts[field.name]}
                                                    onChange={(e)=> handleCashAmountChange(e.target.value)}
                                                    type='number' size='small'
                                                    variant='outlined' fullWidth
                                                    disabled={index !== 0} placeholder={field.label}
                                                />
                                            </Grid>
                                        ))) : (
                                        <Grid item xs={12}>
                                            <TextField
                                                type='number'
                                                value={paymentAmounts.otherMethodsAmount === 0 ? "" : paymentAmounts.otherMethodsAmount}
                                                onChange={(e)=> handleOtherMethods(e.target.value)}
                                                size='small' variant='outlined' fullWidth placeholder={"Amount"}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <TextField
                                            type='number'
                                            value={paymentAmounts.discount === 0 ? "" : paymentAmounts.discount}
                                            onChange={(e)=> handleDiscountChange(e.target.value)}
                                            size='small' variant='outlined' fullWidth placeholder={"Discount"}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            <Button disabled={disablePaymentButtons} sx={{...buttonStyle, minWidth: '225px'}} onClick={()=> addPaymentMethod()}>Add</Button>
                        </Stack>
                        <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem'}}>
                            <Stack direction='row' sx={{alignItems: 'end', columnGap: '1rem', justifyContent: 'end', height: '100%', flexGrow: 1}}>
                                {savedPayments.length > 0 ?
                                    <Stack rowGap='0.5rem' sx={{flexGrow: 1}}>
                                        {savedPayments.map((payment, index)=> (
                                            <Stack direction='row' key={index} sx={{justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', border: `1px solid ${grey[400]}`, padding: '0.25rem 1rem'}}>
                                                <Typography variant='body2' sx={{fontSize: '1rem'}}>{payment.type}</Typography>
                                                <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem'}}>
                                                    <Typography variant='body2' sx={{fontSize: '1rem'}}>{locationData.Currency.currency_symbol} {payment.amount}</Typography>
                                                    <IconButton onClick={()=> handleRemovePaymentMethod(index)} size='small'>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        ))}
                                    </Stack> :
                                    <Typography variant='h5' sx={{color: grey[600], textAlign: 'center', alignSelf: 'center', justifySelf: 'center'}}>Your added payments will be shown here</Typography>
                                }
                                <Stack sx={{textAlign: 'center', alignItems: 'center', width: '205px'}}>
                                    <Typography variant='h6' sx={{color: grey[800]}}>Payable</Typography>
                                    <Typography variant='h5' sx={{fontSize: '600', color: 'black'}}>{locationData.Currency.currency_symbol}{(paymentAmounts.total_amount).toFixed(2)}</Typography>
                                    <Typography variant='h6' sx={{color: grey[800]}}>Paid</Typography>
                                    <Typography variant='h5' sx={{fontSize: '600', color: 'black'}}>{locationData.Currency.currency_symbol}{paymentAmounts.amount === "" ? 0 : paymentAmounts.amount}</Typography>
                                    <Typography variant='h6' sx={{color: grey[800]}}>Due</Typography>
                                    <Typography variant='h5' sx={{fontSize: '600', color: 'black'}}>{locationData.Currency.currency_symbol}{paymentAmounts.due === "" ? 0 : (paymentAmounts.due).toFixed(2)}</Typography>
                                    <Stack direction='row' sx={{alignItems: 'center', columnGap: '0.5rem', marginTop: '0.5rem', width: '100%'}} onClick={()=> setOpenOrderInfo(true)}>
                                        {/*<Button sx={buttonStyle} fullWidth>Send SMS</Button>*/}
                                        <Button sx={buttonStyle} fullWidth>Cart Details</Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack sx={{width: '225px', paddingTop: '0.5rem'}}>
                                <Grid container spacing={1}>
                                    {cashButtons.map((button, index) => (
                                        <Grid key={index} item xs={(index === 8 ? 12 : 6)}>
                                            <Button
                                                disabled={(index !== 0 || index !== 8) && disablePaymentButtons}
                                                onClick={()=> handleAmountButtonsClick(button, index)}
                                                fullWidth sx={buttonStyle}>{(index !== 0 && index !== 8) ? locationData.Currency.currency_symbol : ""}{index === 1 ? paymentAmounts.due : button.label}</Button>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Divider />
            <Stack direction='row' sx={{alignItems: 'center', columnGap: '1rem', padding: '0.5rem 1rem'}}>
                <Button sx={orderDetailModalStyles.saveButton} startIcon={ <ReceiptIcon /> } variant='contained' onClick={()=> handleOrderPayment()}>Submit</Button>
                <Button sx={orderDetailModalStyles.closeButton} startIcon={ <CloseIcon /> } variant='contained' onClick={()=> setOpenModal(false)}>Cancel</Button>
            </Stack>
            {openOrderInfo && <OrderTotalModal openModal={openOrderInfo} setOpenModal={setOpenOrderInfo} order={order} values={values}/> }
        </>
    )
}

export default FinalPaymentModalLargeScreen;